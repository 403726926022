import React, { ReactElement, useEffect, useRef, useState } from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  Dot,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import { ReactComponent as Back } from "../../assets/back-black-24.svg";
import { SliderData, SlideItem, SVGReactComponent } from "./types";

import "./styles.scss";
import styles from "./styles.mod.scss";
import { useScroll } from "../../hooks/useScroll";

const getIconComponent = (Icon: SVGReactComponent) => {
  return (
    <Icon
      style={{ width: "100%", height: "100%" }}
      preserveAspectRatio="xMinYMin meet"
      viewBox="0 0 259 208"
    />
  );
};
interface CarouselProps extends SliderData {
  isScroll?: boolean;
}

export const Carousel = ({ data, isScroll }: CarouselProps): ReactElement => {
  const getSlideParam = (param: "slideNumber" | "slideHeight") => {
    let slideParam = {
      slideNumber: 1,
      slideHeight: 500,
    };
    if (window.innerWidth > 576) {
      slideParam = {
        slideNumber: 2,
        slideHeight: 500,
      };
    }
    if (window.innerWidth > 992) {
      slideParam = {
        slideNumber: 4,
        slideHeight: 500,
      };
    }
    return slideParam[param];
  };
  const [slideNumber, setSlideNumber] = useState(4);
  const [slideHeight, setSlideHeight] = useState(1800);
  const changeSlideParams = () => {
    setSlideNumber(getSlideParam("slideNumber"));
    setSlideHeight(getSlideParam("slideHeight"));
  };
  useEffect(() => {
    changeSlideParams();
    window.addEventListener("resize", changeSlideParams);

    return () => {
      window.removeEventListener("resize", changeSlideParams);
    };
  }, []);

  const getMappedData = () =>
    data.reduce((result: Array<Array<SlideItem>>, _, index, array) => {
      if (index % slideNumber === 0) {
        result.push(array.slice(index, index + slideNumber));
      }
      return result;
    }, []);
  const getDots = () =>
    getMappedData().map((item, index) => {
      return (
        <Dot slide={index} key={index} className={styles.carousel__dot}>
          {""}
        </Dot>
      );
    });
  const getSlideClass = () => {
    return `col-${12 / slideNumber} ${styles.carousel__slide}`;
  };
  const hintsRef = useRef(null);
  useScroll(isScroll || false, hintsRef);
  return (
    <div>
      <CarouselProvider
        naturalSlideWidth={1036}
        naturalSlideHeight={slideHeight}
        totalSlides={getMappedData().length}
        isPlaying={true}
        interval={5000}
      >
        <div className={`${styles.carousel__title} carouselTitle`}>
          <h2 ref={hintsRef} id="hints" className={styles.carousel__titleText}>
            Energiespartipps
          </h2>
          <div>{getDots()}</div>
        </div>
        <div className={styles.carousel__wrapper}>
          {slideNumber === 1 ? (
            <ButtonNext className={styles.carousel__button_back}>
              <Back />
            </ButtonNext>
          ) : null}
          <Slider style={{ height: "500px" }}>
            {getMappedData().map((sliderItems, sliderIndex) => (
              <Slide key={sliderIndex} index={sliderIndex}>
                <div className="row">
                  {sliderItems.map((slideItem, slideItemIndex) => (
                    <div key={slideItemIndex} className={getSlideClass()}>
                      <div className={styles.carousel__icon}>
                        {getIconComponent(slideItem.icon)}
                      </div>
                      <p className={styles.carousel__itemTitle}>
                        {slideItem.title}
                      </p>
                      <p className={styles.carousel__itemDescription}>
                        {slideItem.description}
                      </p>
                    </div>
                  ))}
                </div>
              </Slide>
            ))}
          </Slider>
          {slideNumber === 1 ? (
            <ButtonBack className={styles.carousel__button_next}>
              <Back
                style={{
                  transform: "rotate(180deg)",
                }}
              />
            </ButtonBack>
          ) : null}
        </div>
      </CarouselProvider>
    </div>
  );
};
