import React from "react";
import { carouselData } from "./CarouselData";
import { Carousel } from "./Carousel";

interface CarouselProps {
  isScroll?: boolean;
}
const CarouselWrapper = ({ isScroll }: CarouselProps) => {
  return (
    <div className="container">
      <Carousel isScroll={isScroll} data={carouselData} />
    </div>
  );
};
export default CarouselWrapper;
