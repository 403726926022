import { ReactComponent as Icon1 } from "../../assets/tips-appliance.svg";
import { ReactComponent as Icon2 } from "../../assets/tips-cooking.svg";
import { ReactComponent as Icon3 } from "../../assets/tips-devices.svg";
import { ReactComponent as Icon4 } from "../../assets/tips-heating.svg";
import { ReactComponent as Icon5 } from "../../assets/tips-sustainable.svg";

export const carouselData = [
  {
    title: "Weniger ist mehr",
    description:
      "Parkett, Laminat, Fliesen? Bei harten Böden liegt der Schmutz auf der Oberfläche - und das schafft dein Staubsauger auch auf niedriger Stufe mit links! Nur Teppiche brauchen etwas mehr Power.",
    icon: Icon1,
  },
  {
    title: "Sesam schließe dich",
    description:
      "Aus Langeweile mal wieder nachgeschaut, ob der Kühlschrank auf magische Weise etwas Neues beinhaltet? Jedes Türöffnen kostet Strom. Deshalb unnötiges Auf- und Zumachen wenn möglich vermeiden.",
    icon: Icon1,
  },
  {
    title: "Und es gart doch!",
    description:
      "Dein Elektroherd zaubert dir dein Essen auch dann gar, wenn du die Herdplatte 5 Minuten eher ausschaltest. So kannst du bei jedem Kochgang Energie sparen - Nachwärme sei dank.",
    icon: Icon2,
  },
  {
    title: "Leerlauf",
    description:
      "Eingesteckte Ladegeräte verbrauchen auch dann Strom, wenn sie nichts aufladen, da im Netzteil trotzdem Strom fließt. Deshalb zieh das Ladegerät aus der Steckdose, wenn du fertig mit dem Aufladen bist.",
    icon: Icon3,
  },
  {
    title: "Strom? Öko natürlich!",
    description:
      "Für nachhaltigen Stromkonsum ohne Gewissensbisse lautet die Lösung Ökostrom. Wie praktisch, dass du mit stromee den perfekten Partner für 100 % Ökostrom zum Einkaufspreis an deiner Seite hast.",
    icon: Icon5,
  },
  {
    title: "Eingetütet",
    description:
      "Eben noch schnell in den Supermarkt? Bei ungeplanten Einkäufen muss oft die Plastiktüte herhalten. Mach’s dir zur Gewohnheit, den Stoffbeutel mitzuhaben und du bist für jeden Spontaneinkauf gewappnet.",
    icon: Icon5,
  },
  {
    title: "Gut gedämmt ist halb geheizt",
    description:
      "Mit guter Dämmung lässt sich einiges an Heizkosten sparen. Bei einem Einfamilienhaus (ca. 125 m²) kannst du durch Austauschen einer mangelhaften Dämmung jährlich um die 600 Euro einsparen.",
    icon: Icon4,
  },
  {
    title: "Der kleine Unterschied",
    description:
      "Beim Heizen kannst du direkt 6 % Energie einsparen, wenn du die Raumtemperatur nur um 1° C senkst. Und mit jedem Grad, das du mehr herunterdrehst, sparst du nochmal 6 % ein.",
    icon: Icon4,
  },
  {
    title: "Deckel drauf!",
    description:
      "Ein Deckel auf deinem Kochtopf spart Zeit und Energie. Bis zu 65 % Strom sparst du im Vergleich zum Kochen ohne Deckel.",
    icon: Icon2,
  },
  {
    title: "Die perfekte 7°!",
    description:
      "7 Grad Celsius sind für deinen Kühlschrank kalt genug. Jedes Grad kälter verbraucht 5 % mehr Strom!",
    icon: Icon1,
  },
  {
    title: "Trockene Theorie",
    description:
      "Selbst der energiesparendste Trockner braucht immer noch Strom. Dein Wäscheständer nicht! Mit ihm sparst du direkt 100 %.",
    icon: Icon5,
  },
  {
    title: "Stand-bye bye",
    description:
      "Auch wenn dein Fernseher “gefühlt” aus ist - auch im Standby-Modus verbraucht er Strom. Und das nicht zu wenig. Also öfter mal abschalten, ist die Devise! Dabei helfen dir Steckdosenleisten mit Ausschalter.",
    icon: Icon3,
  },
  {
    title: "Note 1+++",
    description:
      "Bei Elektrogeräten lohnt sich der Blick auf die Energieeffizienzklasse. Ein Kühlschrank der Klasse A+ verbraucht fast doppelt so viel Strom wie einer mit A+++",
    icon: Icon1,
  },
  {
    title: "Vorglühen - nur vor Partys",
    description:
      "Die wenigsten Gerichte brauchen tatsächlich einen vorgeheizten Backofen. Auch Umluft statt Ober- und Unterhitze spart ordentlich an Energie. Beides zusammen sogar bis zu 20 %!",
    icon: Icon2,
  },
  {
    title: "Reste? Beste!",
    description:
      "Lecker gekocht und für den nächsten Tag ist sogar noch etwas übrig? Lass das Essen erst abkühlen, bevor du es in den Kühlschrank stellst. Sonst muss dein Kühlschrank nämlich noch mehr arbeiten, um den Neuzugang herunterzukühlen.",
    icon: Icon5,
  },
  {
    title: "Wasserkocher voraus!",
    description:
      "Nur so viel Wasser erhitzen wie du brauchst und alles unter 1,5 Liter im Wasserkocher - so kannst du eine Menge Energie sparen.",
    icon: Icon1,
  },
];
